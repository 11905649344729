import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Elite FootGear
			</title>
			<meta name={"description"} content={"Elite FootGear is a premium online retailer offering a curated selection of football equipment, trainers, and accessories. "} />
			<meta property={"og:title"} content={"Home | Elite FootGear"} />
			<meta property={"og:description"} content={"Elite FootGear is a premium online retailer offering a curated selection of football equipment, trainers, and accessories. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65326fafc5fd0f0020b155e3/images/gear_3.jpg?v=2023-10-20T13:08:31.563Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65326fafc5fd0f0020b155e3/images/icon13.png?v=2023-10-20T13:11:04.718Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65326fafc5fd0f0020b155e3/images/icon13.png?v=2023-10-20T13:11:04.718Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65326fafc5fd0f0020b155e3/images/icon13.png?v=2023-10-20T13:11:04.718Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65326fafc5fd0f0020b155e3/images/icon13.png?v=2023-10-20T13:11:04.718Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65326fafc5fd0f0020b155e3/images/icon13.png?v=2023-10-20T13:11:04.718Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65326fafc5fd0f0020b155e3/images/icon13.png?v=2023-10-20T13:11:04.718Z"} />
			<meta name={"msapplication-TileColor"} content={"https://uploads.quarkly.io/65326fafc5fd0f0020b155e3/images/icon13.png?v=2023-10-20T13:11:04.718Z"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="140px 0"
			sm-padding="40px 0 40px 0"
			background="linear-gradient(0deg,rgba(0,0,0,.2) 0%,rgba(0,0,0,.2) 100%),--color-dark url(https://uploads.quarkly.io/65326fafc5fd0f0020b155e3/images/ball_1.jpg?v=2023-10-20T12:42:08.781Z) 50% 15%/cover"
			color="--light"
			font="--base"
		>
			<Override slot="SectionContent" sm-align-items="center" />
			<Box width="100%">
				<Text
					as="h1"
					margin="0 0 96px 0"
					text-align="center"
					letter-spacing="50px"
					text-transform="uppercase"
					font="100 72px/1.2 --fontFamily-sans"
					lg-font="200 42px/1.2 --fontFamily-sans"
					lg-letter-spacing="20px"
				>
					Elite FootGear{" "}
				</Text>
			</Box>
			<Box display="flex" margin="-16px -16px -16px -16px" flex-wrap="wrap" width="100%">
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px">
							– Choice for Football Excellence
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px">
							Elite FootGear is a premium online retailer offering a curated selection of football equipment, trainers, and accessories. Tailored for gyms and courts, we provide quality, reliability, and value. Browse our extensive collection and equip your arena with the best.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px">
							Welcome to Elite FootGear – your one-stop solution for high-quality football equipment, trainers, and accessories. Tailored exclusively for gyms and courts, we pride ourselves on sourcing the best in the game to ensure your players have the edge.{" "}
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px">
							Whether it's top-notch footballs, innovative training aids, or dependable court accessories, we're the trusted partner for businesses who believe in nothing but the best for their teams.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box padding="80px 32px 0px 32px" border-radius="24px" margin="0px 0px 64px 0px" sm-padding="60px 36px 60px 36px">
				<Box
					width="90%"
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 6px 0px"
						font="--headline2"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Why Choose Elite FootGear?
					</Text>
				</Box>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-indigo"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						1
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Quality Assurance
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Handpicked selection ensuring reliability and performance.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-indigo"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						2
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Specialisation
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Exclusively serving gyms, courts, and training academies.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-indigo"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						3
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Competitive Prices
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Delivering value without compromising on product quality.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-indigo"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						4
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Prompt Delivery
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Seamless online ordering with expedited shipping options.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Faq />
		<Components.Footer13 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});